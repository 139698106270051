import { createSlice } from "@reduxjs/toolkit";
import { sendForgotPasswordLink, updatePassword } from "./actions";

const initialState = {
  loading: false,
  successfullySent: false,
  errors: null,
  successfullyUpdatedPassword: false,
};

const passwordResetSlice = createSlice({
  name: "passwordReset",
  initialState,
  reducers: {
    resetSuccessfullySent(state) {
      state.successfullySent = false;
    },
    resetSuccessfulPasswordUpdate(state) {
      state.successfullyUpdatedPassword = false;
    },
  },
  extraReducers: (builder) => {
    // Send link
    builder.addCase(sendForgotPasswordLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendForgotPasswordLink.fulfilled, (state, { payload }) => {
      if (payload.errors) {
        state.errors = payload.errors;
      } else {
        state.successfullySent = true;
      }
      state.loading = false;
    });
    builder.addCase(sendForgotPasswordLink.rejected, (state) => {
      state.loading = false;
    });
    // Update password
    builder.addCase(updatePassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePassword.fulfilled, (state, { payload }) => {
      if (payload.errors) {
        state.errors = payload.errors;
      } else {
        state.successfullyUpdatedPassword = true;
      }
      state.loading = false;
    });
    builder.addCase(updatePassword.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { resetSuccessfullySent, resetSuccessfulPasswordUpdate } =
  passwordResetSlice.actions;

export default passwordResetSlice.reducer;
