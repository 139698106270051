import { createSlice } from "@reduxjs/toolkit";
import Pay from "../../types/Pay";
import { sendToast } from "../../utils/helpers";
import {
  getPaymentDetailsFromRef,
  getPaymentSetup,
  requestPaymentLink,
} from "./actions";

const initialState = {
  account: null,
  openModal: false,
  errors: null,
  loading: false,
  paymentLink: null,
  successfullyUpdatedPaymentLink: false,
  payDetails: null as Pay | null,
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    openModal(state) {
      state.openModal = true;
    },
    closeModal(state) {
      state.openModal = false;
    },
    resetUpdatePaymentLink(state) {
      state.successfullyUpdatedPaymentLink = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentSetup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentSetup.fulfilled, (state, { payload }) => {
      if (payload.errors) {
        state.errors = payload.errors;
      } else {
        state.paymentLink = payload.url;
        state.errors = null;
      }
      state.loading = false;
    });
    builder.addCase(getPaymentSetup.rejected, (state) => {
      state.loading = false;
    });
    // Add Payment Link to page
    builder.addCase(requestPaymentLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestPaymentLink.fulfilled, (state, { payload }) => {
      if (payload.errors) {
        state.errors = payload.errors;
      } else {
        state.successfullyUpdatedPaymentLink = true;
        state.errors = null;
      }
      state.loading = false;
    });
    builder.addCase(requestPaymentLink.rejected, (state) => {
      state.loading = false;
    });
    // Get Payment Details from ref
    builder.addCase(getPaymentDetailsFromRef.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getPaymentDetailsFromRef.fulfilled,
      (state, { payload }) => {
        if (payload.message) {
          sendToast("This has already been paid for or is an invalid link.", {
            type: "error",
          });
          state.errors = [
            { error: "This has already been paid for or is an invalid link." },
          ];
        } else {
          state.payDetails = payload;
        }
        state.loading = false;
      }
    );
    builder.addCase(getPaymentDetailsFromRef.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { openModal, closeModal, resetUpdatePaymentLink } =
  paymentsSlice.actions;

export default paymentsSlice.reducer;
