import axios from "axios";
import { navigate } from "gatsby";
import Cookies from "js-cookie";
import { attemptRefresh } from "../utils/axios";

const baseURL: string | undefined = process.env.GATSBY_PUBLIC_APP_API_URL;

let refreshTokenPromise;

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use((config) => {
  const token = Cookies.get("access");
  const refresh = Cookies.get("refresh");
  // If no tokens navigate to home page
  if (!token && !refresh) {
    navigate("/signin/");
    return config;
  }

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(undefined, async (error) => {
  const originalRequest = error.config;
  const refresh = Cookies.get("refresh");
  if (error && error.response && error.response.status === 401 && refresh) {
    if (!refreshTokenPromise) {
      refreshTokenPromise = attemptRefresh(originalRequest, refresh)
        .then((config) => {
          refreshTokenPromise = null;
          return config;
        })
        .catch((err) => {
          refreshTokenPromise = null;
          throw Promise.reject(err);
        });
    }

    return refreshTokenPromise
      .then(() => instance(originalRequest))
      .catch((err) => {
        refreshTokenPromise = null;
        throw Promise.reject(err);
      });
  }

  return Promise.reject(error);
});

export default instance;
