import { createSlice } from "@reduxjs/toolkit";
import {
  ExhibitResponse,
  getEvent,
  getPublicAuction,
  PublicAuctionResponse,
} from "./actions";

export const PublicOptions = {
  AuctionItems: "AuctionItems",
  HowToBid: "HowToBid",
  ContactUs: "ContactUs",
};

const initialState = {
  event: null as ExhibitResponse | null,
  loading: false,
  error: null,
  publicAuction: null as PublicAuctionResponse | null,
  selectedOption: PublicOptions.AuctionItems,
  selectedCategory: "All",
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    selectCategory(state, { payload }) {
      state.selectedCategory = payload;
    },
    selectOption(state, { payload }) {
      state.selectedOption = payload;
    },
    clearError(state) {
      state.error = null;
    },
    resetState(state) {
      state.event = null;
      state.loading = false;
      state.error = null;
      state.publicAuction = null;
      state.selectedOption = PublicOptions.AuctionItems;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEvent.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as any).hasError) {
        state.error = payload;
      } else {
        state.event = payload;
      }
    });
    builder.addCase(getEvent.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as any;
    });
    builder.addCase(getPublicAuction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPublicAuction.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as any).hasError) {
        state.error = payload;
      } else {
        state.publicAuction = payload;
      }
    });
    builder.addCase(getPublicAuction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
    });
  },
});

export const { selectOption, clearError, resetState, selectCategory } =
  eventsSlice.actions;

// Reducer
export default eventsSlice.reducer;
