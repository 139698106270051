import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface HighestBid {
  amount: number;
  bin: boolean;
  currency: string;
}

interface UseBin {
  amount: number;
  bin: boolean;
  currency: string;
}
export interface PublicAuctionItem {
  highest_bid?: HighestBid;
  use_bin?: UseBin,
  image_url: string;
  instagram_url: string;
  name?: string;
  num_bids: number;
  num_bins: number;
  progress: string;
  ends_at: string;
  starts_at: string;
}

export const baseURL: string | undefined =
  process.env.NODE_ENV === "development"
    ? "https://bidddy-staging.fly.dev"
    : process.env.GATSBY_PUBLIC_APP_API_URL;

export const getLiveAuctions = createAsyncThunk<[PublicAuctionItem] | any>(
  "liveAuctions/getLiveAuctions",
  async () => {
    try {
      const response = await axios.get(`${baseURL}/public/live/auction`);

      return response.data.data;
    } catch (err: any) {
      return { hasError: true, error: err.response.data };
    }
  }
);
