import { createSlice } from "@reduxjs/toolkit";
import { CategoryTypes } from "../../enums/CategoryTypes";
import ViewTypes from "../../enums/ViewTypes";

const initialState = {
  selectedViewType: ViewTypes.MultipleCardView,
  selectedCategoryType: CategoryTypes.All,
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    updateFilters(state, { payload }) {
      state.selectedViewType = payload;
    },
    updateCategory(state, { payload }) {
      state.selectedCategoryType = payload;
    },
  },
});

export const { updateFilters, updateCategory } = filtersSlice.actions;

// Reducer
export default filtersSlice.reducer;
