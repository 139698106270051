import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auction } from "../../types/Auction";
import Axios from "../../extensions/Axios";

export interface StoreAuctionPartial {
  storeId: number;
  auctionId: number;
}
export interface ErrorPartial {
  error: Error;
  errorType: string;
}
export const getIndividualAuction = createAsyncThunk<
  Auction | ErrorPartial,
  StoreAuctionPartial
>("individualAuction/getIndividualAuction", async (auctionPartial) => {
  try {
    const response = await Axios.get(
      `/stores/${auctionPartial.storeId}/auctions/${auctionPartial.auctionId}`
    );
    return response.data.data;
  } catch {
    return {
      errorType: "ERROR",
      error: new Error("There was an error fetching auction"),
    };
  }
});

export const deleteIndividualAuction = createAsyncThunk<
  Auction | ErrorPartial,
  StoreAuctionPartial
>("individualAuction/deleteIndividualAuction", async (auctionPartial) => {
  try {
    const response = await Axios.delete(
      `/stores/${auctionPartial.storeId}/auctions/${auctionPartial.auctionId}`
    );
    return response.data.data;
  } catch {
    return {
      errorType: "ERROR",
      error: new Error("There was an error deleting auction"),
    };
  }
});

// select winner and finish auction

export interface SelectWinnerRequestProps {
  bid_id?: number | string;
  auction_id: number | string;
  store_id: number | string;
}

export const selectWinningBid = createAsyncThunk<any, SelectWinnerRequestProps>(
  "auctions/selectWinningBid",
  async (props) => {
    try {
      if (props.bid_id) {
        const response = await Axios.patch(
          `stores/${props.store_id}/auctions/${props.auction_id}/bids/${props.bid_id}/confirm`
        );
        return response.data;
      }
      const response = await Axios.patch(
        `stores/${props.store_id}/auctions/${props.auction_id}/finish`
      );
      return response.data;
    } catch (err: any) {
      return { error: true, data: err.response.data };
    }
  }
);
