import { createSlice } from "@reduxjs/toolkit";
import { Auction } from "../../types/Auction";
import {
  deleteIndividualAuction,
  ErrorPartial,
  getIndividualAuction,
  selectWinningBid,
} from "./actions";

const initialState = {
  auction: null as Auction | null,
  loading: false,
  openIndividualAuction: false,
  error: null as Error | null,
  auctionId: null,
  navigationPath: "",
  successfullyDeleted: false,
  successfullySelectedWinner: false,
};

const individualAuctionSlice = createSlice({
  name: "individualAuction",
  initialState,
  reducers: {
    resetState(state) {
      state.successfullyDeleted = false;
      state.successfullySelectedWinner = false;
    },
    closeAuctionDetail(state) {
      state.auctionId = null;
      state.auction = null;
      state.successfullyDeleted = false;
      state.openIndividualAuction = false;
      state.loading = false;
      state.error = null;
      state.navigationPath = "";
      state.successfullyDeleted = false;
    },
    toggleIndividualAuctionModal(state, { payload }) {
      // If we are closing the modal set auction id to null
      if (!payload) {
        state.auctionId = null;
        state.auction = null;
        state.successfullyDeleted = false;
        state.navigationPath = "";
      }
      state.openIndividualAuction = payload;
    },
    setAuctionId(state, { payload }) {
      state.auctionId = payload;
    },
    setNavigationPath(state, { payload }) {
      state.navigationPath = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIndividualAuction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getIndividualAuction.fulfilled, (state, { payload }) => {
      // state.imageUploadState = payload ? 'UPLOAD_SUCCESS' : 'UPLOAD_FAILURE';
      if ((payload as ErrorPartial) && (payload as ErrorPartial).errorType) {
        state.error = (payload as ErrorPartial).error;
      } else {
        state.auction = payload as Auction;
      }
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getIndividualAuction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
    });

    builder.addCase(deleteIndividualAuction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteIndividualAuction.fulfilled, (state, { payload }) => {
      // state.imageUploadState = payload ? 'UPLOAD_SUCCESS' : 'UPLOAD_FAILURE';
      if ((payload as ErrorPartial) && (payload as ErrorPartial).errorType) {
        state.error = (payload as ErrorPartial).error;
      } else {
        state.successfullyDeleted = true;
      }
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteIndividualAuction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
    });
    // Select Winning Bidder
    builder.addCase(selectWinningBid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(selectWinningBid.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.data;
      } else {
        state.error = null;
        state.successfullySelectedWinner = true;
      }
    });
    builder.addCase(selectWinningBid.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
    });
  },
});

export const {
  closeAuctionDetail,
  toggleIndividualAuctionModal,
  setAuctionId,
  setNavigationPath,
  resetState,
} = individualAuctionSlice.actions;

export default individualAuctionSlice.reducer;
