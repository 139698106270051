import { createSlice } from "@reduxjs/toolkit";
import { CategoryTypes } from "../../enums/CategoryTypes";
import {
  AuctionResults,
  fetchAuctions,
  generateAuctionCode,
  getAuctionById,
  patchAuction,
  saveAuctionPartial,
  ScheduledAuction,
  Counts,
  fetchAuctionCounts,
} from "./actions";

const initialState = {
  auction: null as ScheduledAuction,
  auctions: null as AuctionResults,
  loading: null,
  auctionCode: null,
  error: null,
  successfullyPublished: false,
  successfullyScheduled: false,
  successfullyDrafted: false,
  successfullyUpdatedAuction: false,
  failedUpdate: false,
  lastSetOfAuctions: null as AuctionResults,
  savingDraft: false,
  currentPage: 1 as any,
  progressFilter: CategoryTypes.All as any,
  counts: null as Counts | null,
};

const auctionsSlice = createSlice({
  name: "auctions",
  initialState,
  reducers: {
    setSavingDraft(state, { payload }) {
      state.savingDraft = payload;
    },
    setProgressFilter(state, { payload }) {
      state.progressFilter = payload;
    },
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    resetAuctions(state) {
      state.auction = null;
      state.auctions = null;
      state.loading = null;
      state.error = null;
      state.auctionCode = null;
      state.savingDraft = false;
      state.successfullyPublished = false;
      state.successfullyScheduled = false;
      state.successfullyDrafted = false;
      state.successfullyUpdatedAuction = false;
      state.failedUpdate = false;
      state.lastSetOfAuctions = null;
    },
    resetSuccessfullyPublished(state) {
      state.successfullyPublished = false;
      state.successfullyScheduled = false;
      state.successfullyDrafted = false;
      state.successfullyUpdatedAuction = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuctions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAuctions.fulfilled, (state, { payload }) => {
      state.auctions = payload;
      state.lastSetOfAuctions = payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchAuctions.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
    });
    builder.addCase(saveAuctionPartial.pending, (state) => {
      state.loading = true;
      state.successfullyPublished = false;
    });
    builder.addCase(saveAuctionPartial.fulfilled, (state, { payload }) => {
      state.auction = payload;
      state.loading = false;
      state.error = null;
      state.successfullyPublished = true;
    });
    builder.addCase(saveAuctionPartial.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
      state.successfullyPublished = false;
    });
    builder.addCase(patchAuction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchAuction.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.data;
        state.failedUpdate = true;
      } else {
        state.successfullyUpdatedAuction = true;
        state.auction = payload as ScheduledAuction;
        state.failedUpdate = false;
        state.error = null;
      }
    });
    builder.addCase(patchAuction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
      state.failedUpdate = true;
      state.successfullyUpdatedAuction = false;
    });
    // Generate Auction Code for multi images
    builder.addCase(generateAuctionCode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(generateAuctionCode.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.data;
      } else {
        state.error = null;
        state.auctionCode = payload;
      }
    });
    builder.addCase(generateAuctionCode.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
    });

    // Get Auction From Id
    builder.addCase(getAuctionById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAuctionById.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.data;
      } else {
        state.error = null;
        state.auction = payload;
      }
    });
    builder.addCase(getAuctionById.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
    });
    // Get Auction Counts
    builder.addCase(fetchAuctionCounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAuctionCounts.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.data;
      } else {
        state.error = null;
        state.counts = payload;
      }
    });
    builder.addCase(fetchAuctionCounts.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
    });
  },
});

export const {
  setCurrentPage,
  setProgressFilter,
  resetSuccessfullyPublished,
  resetAuctions,
  setSavingDraft,
} = auctionsSlice.actions;

// Reducer
export default auctionsSlice.reducer;
