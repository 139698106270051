import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { UserAuth, UserAuthError } from "../../types/UserAuth";
import { baseURL } from "../../utils/axios";
import { withToastForError } from "../../utils/helpers";

export interface UserRegistrationProps {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

export interface UserLogin {
  email: string;
  password: string;
}

export interface BidderLogin {
  email: string;
  redirect: string;
}

export interface LoginProps {
  user?: UserLogin;
  bidder?: BidderLogin;
  bid?: number | string;
}
export interface RegistrationProps {
  user: UserRegistrationProps;
}
//
// authenticating with instagram and getting all the proper scopes (NOT LOGIN/SIGNUP)
// localhost:3000/auth/facebook?scope=email,instagram_basic,pages_show_list,instagram_manage_comments
//

export const signUserUp = createAsyncThunk<
  UserAuth | UserAuthError,
  RegistrationProps
>(
  "user/signUp",
  withToastForError(async (registrationProps) => {
    const response = await axios.post(`${baseURL}/signup`, {
      ...registrationProps,
    });
    return response.data;
  })
);

export const signIn = createAsyncThunk<UserAuth | UserAuthError, LoginProps>(
  "user/signIn",
  withToastForError(async (loginProps) => {
    const response = await axios.post(`${baseURL}/login`, { ...loginProps });
    return response.data;
  })
);
