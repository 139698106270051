import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "../../utils/axios";
import Axios from "../../extensions/Axios";
import { withToastForError } from "../../utils/helpers";

export interface StoreCreationProps {
  name: "string";
}

export interface PaymentLive {
  live: boolean;
}

export interface ImageData {
  filename: string;
  mime_type: string;
  path: string;
  settings: object;
}

export interface ProfileImage {
  type: string;
  data: ImageData;
}

export interface StoreProps {
  id: number | string;
  name: string;
  payment_links_enabled: boolean;
  profile_image: ProfileImage | null;
  payment_account: PaymentLive | null;
}

export const getStore = createAsyncThunk<StoreProps[]>(
  "stores/getStore",
  withToastForError(async () => {
    // TODO: add proper request handling here.
    const response = await Axios.get(`${baseURL}/stores`);

    return response.data;
  })
);

export const getStoreById = createAsyncThunk<StoreProps, any>(
  "stores/getStoreById",
  withToastForError(async (id) => {
    // TODO: add proper request handling here.
    const response = await Axios.get(`${baseURL}/stores/${id}`);

    return response.data.data;
  })
);
