import { createAsyncThunk } from "@reduxjs/toolkit";
import { StoreAuctionPartial } from "../individualAuction/actions";
import Axios from "../../extensions/Axios";

export interface Bid {
  amount: number;
  currency: string;
  id: number;
  name: string;
  created_at: string;
  active: boolean;
  deactivator: string;
  winner: boolean;
  bin: boolean;
}

export interface BidActivity {
  data: Bid[];
}

export interface DeleteBidProps {
  storeId: string | number;
  auctionId: string | number;
  bidId: string | number;
}

export const fetchBids = createAsyncThunk<Bid[], StoreAuctionPartial>(
  "bidActivity/fetchBids",
  async (props) => {
    const response = await Axios.get(
      `/stores/${props.storeId}/auctions/${props.auctionId}/bids/`
    );
    return response.data.data;
  }
);

export const deleteBid = createAsyncThunk<any, DeleteBidProps>(
  "bidActivity/deleteBid",
  async (props) => {
    const response = await Axios.delete(
      `/stores/${props.storeId}/auctions/${props.auctionId}/bids/${props.bidId}`
    );
    return response.data;
  }
);
