export const SignUpPageStep = {
  CreateAccount: "Create a Bidddy Profile.",
  AddSocialAccount: "Add Social Account.",
  AddPaymentMethod: "Add Payment Method.",
  ReadyToRoll: "Ready To Roll?",
};

export const getSignUpPageStepDescription = (pageStep) => {
  switch (pageStep) {
    case SignUpPageStep.CreateAccount:
      return "Let's keep it simple. All we need right now is your name, an email and password to get started.";
    case SignUpPageStep.AddSocialAccount:
      return "A social (Instagram) account is required to connect and post a live auction, but you can skip this step for now if you like...";
    default:
      return `<p>Your account is ready to go! You can access it at anytime to edit settings, social accounts and payment methods.<p><br/><br/><p>Thanks. Please social responsibly.</p>`;
  }
};

export const getSignUpPageStepNumber = (pageStep) => {
  switch (pageStep) {
    case SignUpPageStep.CreateAccount:
      return 0;
    case SignUpPageStep.AddSocialAccount:
      return 1;
    default:
      return 2;
  }
};
