import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { UserAuth } from "../../types/UserAuth";
import { signIn, signUserUp } from "./actions";
import { SignUpPageStep } from "../../enums/SignUpPageStep";
import {
  ConnectionSteps,
  getNextStepFromResponse,
  onBack,
} from "../../enums/ConnectionSteps";
import { PaymentSteps } from "../../enums/PaymentSteps";
import { sendToast } from "../../utils/helpers";

const initialState = {
  loading: false,
  errors: null,
  success: false,
  currentStep: SignUpPageStep.CreateAccount,
  lastConnectionStep: null as string | null,
  currentConnectionStep: ConnectionSteps.Introduction,
  currentPaymentStep: PaymentSteps.ConnectPayment,
  isSliding: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearSuccessState(state) {
      state.success = false;
    },
    nextPaymentStep(state) {
      if (state.currentPaymentStep === PaymentSteps.ConnectPayment) {
        state.currentPaymentStep = PaymentSteps.AddPayment;
      }
    },
    nextConnectionStepFromAnswer(state, { payload }) {
      state.isSliding = true;
      const oldStep = state.currentConnectionStep;
      const newStep = getNextStepFromResponse(payload, oldStep);
      state.currentConnectionStep = newStep;
      state.lastConnectionStep = oldStep;
      state.isSliding = false;
    },
    onBackConnectionStep(state) {
      state.isSliding = true;
      const lastStep = state.currentConnectionStep;
      const backStep = onBack(state.currentConnectionStep);
      state.currentConnectionStep = backStep;
      state.lastConnectionStep = lastStep;
      state.isSliding = false;
    },
    nextStep() {},
    previousStep() {},
    resetDefaults(state) {
      state.currentStep = SignUpPageStep.CreateAccount;
      state.currentConnectionStep = ConnectionSteps.Introduction;
      state.success = false;
      state.loading = false;
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUserUp.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(signUserUp.fulfilled, (state, { payload }) => {
      const auth = payload as UserAuth;
      try {
        const decodedAccess: { [key: string]: any } = jwtDecode(auth.token);
        const decodedRefresh: { [key: string]: any } = jwtDecode(
          auth.refresh_token
        );
        let accountType = auth.type;
        if (!accountType || accountType === "undefined") {
          accountType = "user";
        }
        Cookies.set("accountType", accountType, {
          expires: new Date(decodedRefresh.exp * 1000),
        });
        Cookies.set("access", auth.token, {
          expires: new Date(decodedAccess.exp * 1000),
        });
        Cookies.set("refresh", auth.refresh_token, {
          expires: new Date(decodedRefresh.exp * 1000),
        });
      } catch (e) {
        console.log("Error logging in");
      } finally {
        state.loading = false;
        state.success = true;
      }
    });
    builder.addCase(signUserUp.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      const auth = payload as UserAuth;
      try {
        const decodedAccess: { [key: string]: any } = jwtDecode(auth.token);
        const decodedRefresh: { [key: string]: any } = jwtDecode(
          auth.refresh_token
        );
        let accountType = auth.type;
        if (!accountType || accountType === "undefined") {
          accountType = "user";
        }
        Cookies.set("accountType", accountType, {
          expires: new Date(decodedRefresh.exp * 1000),
        });
        Cookies.set("access", auth.token, {
          expires: new Date(decodedAccess.exp * 1000),
        });
        Cookies.set("refresh", auth.refresh_token, {
          expires: new Date(decodedRefresh.exp * 1000),
        });
      } catch (e) {
        console.log("Error logging in");
      } finally {
        state.loading = false;
        state.success = true;
      }
    });
    builder.addCase(signIn.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  nextPaymentStep,
  previousStep,
  nextStep,
  resetDefaults,
  nextConnectionStepFromAnswer,
  onBackConnectionStep,
  clearSuccessState,
} = authSlice.actions;

export default authSlice.reducer;
