import { createSlice } from "@reduxjs/toolkit";
import { getStore, getStoreById } from "./actions";

const initialState = {
  loading: false,
  errors: null,
  store: null,
};

const storeSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    resetStore(state) {
      state.loading = false;
      state.errors = null;
      state.store = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStore.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getStore.fulfilled, (state, { payload }) => {
      state.store = payload;
      state.loading = false;
    });
    builder.addCase(getStore.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getStoreById.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getStoreById.fulfilled, (state, { payload }) => {
      state.store = payload;
      state.loading = false;
    });
    builder.addCase(getStoreById.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { resetStore } = storeSlice.actions;
export default storeSlice.reducer;
