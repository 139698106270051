import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const baseURL: string | undefined =
  process.env.GATSBY_PUBLIC_APP_API_URL;

export interface PublicAuction {
  id: string;
  bid_count: number;
  current_bid_price: number;
  ends_at: string;
  image_url: string;
  name: string;
  progress: string;
  starts_at: string;
  username: string; // market username
  price: number;
  currency: string;
  increment: number;
  image_urls: string[];
}

export interface PublicAuctionLink {
  auction: PublicAuction;
  description: string | null;
  exhibit_id: string;
  name: string | null;
  permalink: string | null;
  token: string;
  username: string; // artist username
  exhibit_categories: Category[];
}

export interface Category {
  id: number;
  name: string;
}

export interface Exhibit {
  logo_url: string;
  name: string;
  slug: string;
  auction_public_links: PublicAuctionLink[];
  date_range: string;
  description: string;
  profile_url: string;
  username: string;
  categories: Category[];
  email: string;
  contact_md: string;
  how_md: string;
  signup_url: string;
}

export interface ExhibitResponse {
  data: Exhibit;
}

export interface PublicAuctionResponse {
  data: PublicAuctionLink;
}

export const getEvent = createAsyncThunk<ExhibitResponse, string>(
  "events/getEvent",
  async (id) => {
    try {
      const response = await axios.get(`${baseURL}/public/exhibit/${id}`);

      return response.data;
    } catch (err: any) {
      return { hasError: true, error: err.response.data };
    }
  }
);

export const getPublicAuction = createAsyncThunk<PublicAuctionResponse, string>(
  "events/getPublicAuction",
  async (id) => {
    try {
      const response = await axios.get(`${baseURL}/public/auction/${id}`);

      return response.data;
    } catch (err: any) {
      return { hasError: true, error: err.response.data };
    }
  }
);
