import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../utils/axios";

export interface ForgotPasswordObject {
  reset_token: string;
  password: string;
}

export const sendForgotPasswordLink = createAsyncThunk<any, string>(
  "passwordReset/sendForgotPasswordLink",
  async (email) => {
    try {
      const response = await axios.post(`${baseURL}/user/password`, {
        user: {
          email,
        },
      });

      return response.data;
    } catch (error) {
      const unwrappedError: any = error;
      return unwrappedError.response.data;
    }
  }
);

export const updatePassword = createAsyncThunk<any, ForgotPasswordObject>(
  "passwordReset/updatePassword",
  async (forgotPasswordObject) => {
    try {
      const response = await axios.patch(`${baseURL}/user/password`, {
        user: {
          reset_token: forgotPasswordObject.reset_token,
          password: forgotPasswordObject.password,
        },
      });
      return response.data;
    } catch (error) {
      const unwrappedError: any = error;
      return unwrappedError.response.data;
    }
  }
);
