import { createSlice } from "@reduxjs/toolkit";
import { sendToast } from "../../utils/helpers";
import {
  ConnectionSteps,
  getNextStepFromResponse,
  onBack,
} from "../../enums/ConnectionSteps";
import { Auction } from "../../types/Auction";
import { Post } from "../../types/Post";
import {
  getUploadImageUrl,
  NewUpload,
  selectPost,
  uploadImage,
} from "./actions";

export const AUCTION_STATE = {
  CREATE_AUCTION: "CREATE AUCTION",
  CHOOSE_ACCOUNT: "CHOOSE ACCOUNT",
  ADD_IMAGE: "ADD IMAGE",
  ADD_DESCRIPTION: "ADD DESCRIPTION",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  PREVIEW: "PREVIEW",
  HOW_TO_BID: "HOW TO BID",
  SHIPPING: "SHIPPING",
  PAYMENT: "PAYMENT",
  HIDDEN: "HIDDEN",
  CONFIRMATION: "CONFIRMATION",
};

const initialState = {
  auction: {} as Auction | null,
  openModal: false,
  loading: false,
  linkedPost: null,
  error: null,
  successfullyUpdatedAuction: false,
  currentState: AUCTION_STATE.HIDDEN,
  lastStepState: AUCTION_STATE.HIDDEN,
  uploadImageData: null as NewUpload,
  lastConnectionStep: null as string | null,
  currentConnectionStep: ConnectionSteps.Introduction,
  imageUploadState: "NOT_UPLOADED",
  isEditing: false,
  path: null,
};

const auctionModalSlice = createSlice({
  name: "auctionModal",
  initialState,
  reducers: {
    resetError(state) {
      state.error = null;
    },
    resetAuctionModal(state) {
      state.auction = {} as Auction;
      state.openModal = false;
      state.loading = false;
      state.linkedPost = null;
      state.error = null;
      state.successfullyUpdatedAuction = false;
      state.uploadImageData = null;
      state.lastConnectionStep = null;
      state.currentConnectionStep = ConnectionSteps.Introduction;
      state.imageUploadState = "NOT_UPLOADED";
      state.isEditing = false;
    },
    clearIsEditing(state) {
      state.isEditing = false;
    },

    setPath(state, { payload }) {
      state.path = payload;
    },
    nextConnectionStepFromAnswer(state, { payload }) {
      const oldStep = state.currentConnectionStep;
      const newStep = getNextStepFromResponse(payload, oldStep);
      state.currentConnectionStep = newStep;
      state.lastConnectionStep = oldStep;
    },
    onBackConnectionStep(state) {
      const lastStep = state.currentConnectionStep;
      const backStep = onBack(state.currentConnectionStep);
      state.currentConnectionStep = backStep;
      state.lastConnectionStep = lastStep;
    },
    navigateBackwards(state) {
      switch (state.currentState) {
        case AUCTION_STATE.CREATE_AUCTION:
          state.currentState = AUCTION_STATE.HIDDEN;
          state.openModal = false;
          return;
        case AUCTION_STATE.CHOOSE_ACCOUNT:
          if (state.currentConnectionStep === ConnectionSteps.Introduction) {
            state.lastStepState = AUCTION_STATE.CHOOSE_ACCOUNT;
            state.currentState = AUCTION_STATE.CREATE_AUCTION;
          } else {
            const lastStep = state.currentConnectionStep;
            const backStep = onBack(state.currentConnectionStep);
            state.currentConnectionStep = backStep;
            state.lastConnectionStep = lastStep;
          }

          return;
        case AUCTION_STATE.ADD_IMAGE:
          state.lastStepState = AUCTION_STATE.ADD_IMAGE;
          state.currentState = AUCTION_STATE.CHOOSE_ACCOUNT;
          return;
        case AUCTION_STATE.ADD_DESCRIPTION:
          state.lastStepState = AUCTION_STATE.ADD_DESCRIPTION;
          state.currentState = AUCTION_STATE.ADD_IMAGE;
          return;
        case AUCTION_STATE.HOW_TO_BID:
        case AUCTION_STATE.SHIPPING:
        case AUCTION_STATE.PAYMENT:
        case AUCTION_STATE.PREVIEW:
          state.lastStepState = AUCTION_STATE.PREVIEW;
          state.currentState = AUCTION_STATE.ADD_DESCRIPTION;
          return;
        // TODO: handle PAYMENT_METHOD
        default:
          state.currentState = AUCTION_STATE.HIDDEN;
          state.openModal = false;
      }
    },
    nextStep(state) {
      switch (state.currentState) {
        case AUCTION_STATE.CREATE_AUCTION:
          state.lastStepState = AUCTION_STATE.CREATE_AUCTION;
          state.currentState = AUCTION_STATE.CHOOSE_ACCOUNT;
          return;
        case AUCTION_STATE.CHOOSE_ACCOUNT:
          state.lastStepState = AUCTION_STATE.CHOOSE_ACCOUNT;
          state.currentState = AUCTION_STATE.ADD_IMAGE;
          return;
        case AUCTION_STATE.ADD_IMAGE:
          state.lastStepState = AUCTION_STATE.ADD_IMAGE;
          state.currentState = AUCTION_STATE.ADD_DESCRIPTION;
          return;
        case AUCTION_STATE.ADD_DESCRIPTION:
          state.lastStepState = AUCTION_STATE.ADD_DESCRIPTION;
          state.currentState = AUCTION_STATE.PREVIEW;
          return;
        case AUCTION_STATE.HOW_TO_BID:
        case AUCTION_STATE.SHIPPING:
        case AUCTION_STATE.PAYMENT:
          state.currentState = AUCTION_STATE.ADD_DESCRIPTION;
          return;
        case AUCTION_STATE.PREVIEW:
          state.lastStepState = AUCTION_STATE.PREVIEW;
          state.currentState = AUCTION_STATE.CONFIRMATION;
          return;
        // TODO: HANDLE PAYMENT_METHOD
        case AUCTION_STATE.CONFIRMATION:
          state.lastStepState = AUCTION_STATE.CONFIRMATION;
          state.currentState = AUCTION_STATE.HIDDEN;
          return;
        default:
          state.currentState = AUCTION_STATE.HIDDEN;
          state.openModal = false;
      }
    },
    openCreateAuctionModal(state) {
      state.openModal = true;
      state.uploadImageData = null;
      state.auction = {} as Auction;
      state.linkedPost = null;
      state.currentState = AUCTION_STATE.CREATE_AUCTION;
      state.loading = false;
      state.linkedPost = null;
      state.error = null;
      state.successfullyUpdatedAuction = false;
      state.uploadImageData = null;
      state.lastConnectionStep = null;
      state.currentConnectionStep = ConnectionSteps.Introduction;
      state.imageUploadState = "NOT_UPLOADED";
      state.isEditing = false;
    },
    resetAuctionModalState(state) {
      state.auction = {} as Auction;
      state.isEditing = false;
      state.linkedPost = null;
      state.loading = false;
      state.uploadImageData = null;
      state.imageUploadState = "NOT_UPLOADED";
    },
    closeCreateAuctionModal(state) {
      state.openModal = false;
      state.auction = {} as Auction;
      state.linkedPost = null;
      state.uploadImageData = null;
      state.loading = false;
      state.imageUploadState = "NOT_UPLOADED";
      state.currentState = AUCTION_STATE.HIDDEN;
      state.lastStepState = AUCTION_STATE.HIDDEN;
    },
    updateAuction(state, { payload }) {
      state.auction = { ...payload } as Auction;
    },
    editAuction(state, { payload }) {
      const editedAuction = payload as Auction;
      state.openModal = true;
      state.auction = { ...editedAuction } as Auction;
      state.currentState = AUCTION_STATE.CREATE_AUCTION;
      state.lastStepState = AUCTION_STATE.HIDDEN;
      state.isEditing = true;
    },
    removeAuctionImage(state, { payload }) {
      const newFiles = state.auction.item.files.filter(
        (file) => file.url !== payload.url
      );
      state.auction = {
        ...state.auction,
        item: { ...state.auction.item, files: newFiles },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectPost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(selectPost.fulfilled, (state, { payload }) => {
      state.linkedPost = payload as Post;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(selectPost.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
    });
    builder.addCase(getUploadImageUrl.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUploadImageUrl.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.error =
          "There was an error uploading the image. Please Try again.";
        sendToast("There was an error uploading image. Please try again", {
          type: "error",
        });
      } else {
        state.uploadImageData = payload;
        state.error = null;
        state.imageUploadState = "NOT_UPLOADED";
      }
    });
    builder.addCase(getUploadImageUrl.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
    });
    builder.addCase(uploadImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadImage.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.imageUploadState = "UPLOAD_FAILURE";
        state.error = payload.message;
        sendToast("There was an error uploading image. Please try again", {
          type: "error",
        });
      } else {
        state.imageUploadState = payload ? "UPLOAD_SUCCESS" : "UPLOAD_FAILURE";
        state.error = null;
        const newAuction = state.auction;
        if (newAuction.item.files) {
          newAuction.item.files.push({
            type: "image",
            data: {
              filename: state.uploadImageData.data.meta.filename,
              path: state.uploadImageData.data.meta.path,
              mime_type: state.uploadImageData.data.meta.mime_type,
              settings: {},
            },
            url: state.uploadImageData.data.imgix_url,
          });
        } else {
          newAuction.item.files = [
            {
              type: "image",
              data: {
                filename: state.uploadImageData.data.meta.filename,
                path: state.uploadImageData.data.meta.path,
                mime_type: state.uploadImageData.data.meta.mime_type,
                settings: {},
              },
              url: state.uploadImageData.data.imgix_url,
            },
          ];
        }

        state.auction = newAuction;
        state.uploadImageData = null;
      }
    });
    builder.addCase(uploadImage.rejected, (state, { payload }) => {
      state.loading = false;
      state.imageUploadState = "NOT_UPLOADED";
      state.error = payload as Error;
      sendToast("There was an error uploading image. Please try again", {
        type: "error",
      });
    });
  },
});

export const {
  nextConnectionStepFromAnswer,
  onBackConnectionStep,
  nextStep,
  navigateBackwards,
  openCreateAuctionModal,
  closeCreateAuctionModal,
  updateAuction,
  editAuction,
  clearIsEditing,
  resetAuctionModalState,
  resetAuctionModal,
  removeAuctionImage,
  setPath,
  resetError,
} = auctionModalSlice.actions;

export default auctionModalSlice.reducer;
