export const ConnectionSteps = {
  Introduction: "Introduction",
  DoYouHaveABusinessAccount: "DoYouHaveABusinessAccount",
  ReadyToConnect: "ReadyToConnect",
  ListOfAccounts: "ListOfAccounts",
  NoBusinessAccount: "NoBusinessAccount",
  Connected: "Connected",
};

export const Answers = {
  Yes: "Yes",
  No: "No",
};

export const getNextStepFromResponse = (answer, step) => {
  if (step === ConnectionSteps.Introduction) {
    return ConnectionSteps.DoYouHaveABusinessAccount;
  }
  if (step === ConnectionSteps.DoYouHaveABusinessAccount) {
    if (answer === Answers.Yes) {
      return ConnectionSteps.ReadyToConnect;
    }
    return ConnectionSteps.NoBusinessAccount;
  }
  return ConnectionSteps.Connected;
};

export const onBack = (step) => {
  switch (step) {
    case ConnectionSteps.ListOfAccounts:
    case ConnectionSteps.Introduction:
      return null;
    case ConnectionSteps.DoYouHaveABusinessAccount:
      return ConnectionSteps.Introduction;
    case ConnectionSteps.ReadyToConnect:
      return ConnectionSteps.DoYouHaveABusinessAccount;
    case ConnectionSteps.NoBusinessAccount:
      return ConnectionSteps.DoYouHaveABusinessAccount;
    default:
      return null;
  }
};
