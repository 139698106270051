import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../extensions/Axios";
import { Auction, BidObject, DescriptionObject } from "../../types/Auction";
import { Product } from "../../types/Product";
import { AuctionPartial } from "../auctionModal/actions";

export interface AuctionRequest {
  runningAuctions: Auction[];
  completedAuctions: Auction[];
  createdAuctions: Auction[];
}
/// /
// Async Thunk Actions
/// /
export interface ScheduledAuction {
  description: DescriptionObject;
  ends_at: string;
  id: number;
  item: Product;
  currency: string;
  market_id: number;
  name: string;
  progress: string;
  starts_at: string;
  auction_code: AuctionCode;
  type: string;
  bids_summary: BidObject;
}

export interface AuctionCodeRequestParams {
  storeId: number | string;
  auctionId: number | string;
}

export interface AuctionCode {
  code: string;
  expired: boolean;
  id: number;
  trigger: string;
}

export interface Meta {
  page: number;
  page_size: number;
  sort: string; // id, etc
  sort_order: string; // asc / desc
  total_entries: number;
  total_pages: number;
}
// page: 1
// page_size: 30
// sort: "id"
// sort_order: "asc"
// total_entries: 200
// total_pages: 7
export interface AuctionResults {
  meta: Meta;
  data: ScheduledAuction[];
}
export type AuctionRequestProps = {
  id: number;
  page?: number;
  page_size?: number;
  sort_order?: string;
  sort?: string;
  progress?: string; // await, draft, schedule, finish, publish, await
};

const getKeysToFilterString = (props) => {
  const keyString = Object.keys(props)
    .filter((prop) => prop !== "id")
    .map((prop) => `${prop}=${props[prop]}`)
    .join("&");
  return keyString;
};

export const fetchAuctions = createAsyncThunk<
  AuctionResults,
  AuctionRequestProps
>("auctions/fetchAuctions", async (props) => {
  try {
    const response = await Axios.get(
      `/stores/${props.id}/auctions${
        Object.keys(props).length > 1 ? "?" : ""
      }${getKeysToFilterString(props)}`
    );
    return response.data;
  } catch (err: any) {
    return { hasError: true, error: err.response.data };
  }
});

export const saveAuctionPartial = createAsyncThunk<
  ScheduledAuction,
  AuctionPartial
>("auctions/saveAuctionPartial", async (auctionPartial) => {
  // Save Auction Partial
  try {
    const response = await Axios.post(
      `/stores/${auctionPartial.userInfo.data.last_store_id}/auctions`,
      {
        auction: {
          ...auctionPartial.auction,
        },
      }
    );
    return response.data.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const generateAuctionCode = createAsyncThunk<
  AuctionCode | any,
  AuctionCodeRequestParams
>("auctions/generateAuctionCode", async (params) => {
  try {
    const response = await Axios.post(
      `/stores/${params.storeId}/auctions/${params.auctionId}/auction_codes`
    );
    return response.data.data;
  } catch (err: any) {
    return { error: true, data: err.response.data };
  }
});

// Deprecated
// export const saveNewAuction = createAsyncThunk<Auction, ScheduledAuction>(
//   'auctions/saveNewAuction',
//   async (auction) => {
//     // API: save auction
//     await timeout(3000)
//     return auction
//   }
// )

export const getAuctionById = createAsyncThunk<
  ScheduledAuction | any,
  AuctionCodeRequestParams
>("auctions/getAuctionById", async (params) => {
  try {
    const response = await Axios.get(
      `/stores/${params.storeId}/auctions/${params.auctionId}`
    );

    return response.data.data;
  } catch (err: any) {
    return { error: true, data: err.response };
  }
});

export interface ScheduleParams {
  storeId: string | number;
  auctionId: string | number;
}

export const patchAuction = createAsyncThunk<
  ScheduledAuction | any,
  AuctionPartial
>("auctions/patchAuction", async (updateProps) => {
  try {
    const response = await Axios.put(
      `/stores/${updateProps.userInfo.data.last_store_id}/auctions/${updateProps.auction.id}`,
      {
        auction: {
          ...updateProps.auction,
        },
      }
    );
    return response.data.data;
  } catch (err: any) {
    return { error: true, data: err.response.data };
  }
});

export interface AuctionCountProps {
  storeId: string | number;
}

export interface Counts {
  draft: number;
  await: number;
  schedule: number;
  publish: number; // live
  finish: number;
}

export const fetchAuctionCounts = createAsyncThunk<
  Counts | any,
  AuctionCountProps
>("auctions/fetchAuctionCounts", async (props) => {
  try {
    const response = await Axios.get(`stores/${props.storeId}/auction/counts`);
    return response.data.data;
  } catch (err: any) {
    return { error: true, data: err.response.data };
  }
});
