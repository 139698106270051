import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../extensions/Axios";

enum BidErrorTypes {
  "bid_low" = "The bid you entered was too low. Please try again.",
  "auction_finished"  = "Sorry, the auction has finished.",
  "invalid_bid" = "Sorry, the bid you entered was invalid. Please try again.",
}

export interface BidderBid {
  amount: number;
}

export interface BidderBidProps {
  bid: BidderBid;
  token: string; // auction token hash thingy
}

export const submitBid = createAsyncThunk<any, BidderBidProps>(
  "bidding/submitBid",
  async (props) => {
    try {
      const response = await Axios.post(`/bids`, {
        ...props,
      });
      return response.data.data;
    } catch (err) {
      const typedError: any = err;
      let message = "";
      if (typedError.response.data.errors) {
        Object.keys(typedError.response.data.errors).forEach((key) => {
          message +=
            BidErrorTypes[typedError.response.data.errors[key][0]] ||
            "Something went wrong. Please try again";
          message += "\n\n";
        });
      }
      return {
        error: true,
        message,
      };
    }
  }
);
