import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Axios from "../../extensions/Axios";
import { baseURL } from "../../utils/axios";

export interface RequestPaymentLinkProps {
  storeId: number;
  auctionId: number;
}

export const getPaymentSetup = createAsyncThunk<any>(
  "payments/getPaymentSetup",
  async () => {
    try {
      const response = await Axios.get(`/payments/accounts/new?format=json`);
      return response.data;
    } catch (error) {
      const unwrappedError: any = error;
      return unwrappedError.response.data;
    }
  }
);

export const requestPaymentLink = createAsyncThunk<
  any,
  RequestPaymentLinkProps
>("payments/requestPaymentLink", async (props) => {
  try {
    const response = await Axios.get(
      `/stores/${props.storeId}/auctions/${props.auctionId}/payment_link`
    );
    return response.data;
  } catch (error) {
    const unwrappedError: any = error;
    return unwrappedError.response.data;
  }
});

export const getPaymentDetailsFromRef = createAsyncThunk<any, string>(
  "payments/getPaymentDetailsFromRef",
  async (ref) => {
    try {
      const response = await axios.get(`${baseURL}/pay/${ref}`);
      return response.data.data;
    } catch (error) {
      return { message: "Not Found" };
    }
  }
);
