import React from "react";
import "../../styles/index.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "../../store";

const ReduxWrapper = ({ element }: any) => (
  <Provider store={store}>
    {element}
    <ToastContainer limit={1} />
  </Provider>
);

export default ReduxWrapper;
