import jwtDecode from "jwt-decode";
import axios from "axios";
import Cookies from "js-cookie";
import { navigate } from "gatsby";

export const baseURL: string | undefined =
  process.env.GATSBY_PUBLIC_APP_API_URL;

export const refreshAuthTokensIfNeeded = async (
  refresh: string
): Promise<boolean> => {
  // if token is expired we use the refresh token to get a new token
  try {
    // Refresh token
    const response = await axios.post(`${baseURL}/refresh`, {
      refresh_token: refresh,
    });

    const newAccess = response.data.token;
    const newRefresh = response.data.refresh_token;
    const accountType = response.data.type;

    const decodedAccess: { [key: string]: any } = jwtDecode(newAccess);
    const decodedRefresh: { [key: string]: any } = jwtDecode(newRefresh);
    Cookies.set("access", newAccess, {
      expires: new Date(decodedAccess.exp * 1000),
    });
    if (accountType) {
      Cookies.set("accountType", accountType, {
        expires: new Date(decodedAccess.exp * 1000),
      });
    }
    Cookies.set("refresh", newRefresh, {
      expires: new Date(decodedRefresh.exp * 1000),
    });
    return true;
  } catch (e) {
    // Throw the error
    throw new Error("Refresh Failed");
  }
};

export const attemptRefresh = async (config, refresh) => {
  const access = Cookies.get("access");
  const updatedConfig = config;
  if (access) {
    try {
      const decodedToken: { [key: string]: any } = jwtDecode(access);
      // Check if token has expired
      if (decodedToken.exp * 1000 < Date.now()) {
        Cookies.remove("access");
        await refreshAuthTokensIfNeeded(refresh);
        // Was refreshed
        const newToken = Cookies.get("access");
        updatedConfig.headers.Authorization = `Bearer ${newToken}`;
        return updatedConfig;
      }
      updatedConfig.headers.Authorization = `Bearer ${access}`;
      return updatedConfig;
    } catch (e) {
      console.error("Error removing token");
      throw new Error("Failed decoding tokens");
    }
  }
  try {
    await refreshAuthTokensIfNeeded(refresh);
    // Was refreshed
    const newToken = Cookies.get("access");
    config.headers.Authorization = `Bearer ${newToken}`;
    return config;
  } catch (e) {
    Cookies.remove("access");
    Cookies.remove("refresh");
    navigate("/signin");
    throw new Error("Refresh Failed");
  }
};

export const isAuthenticated = () => {
  if (Cookies.get("access") || Cookies.get("refresh")) {
    return true;
  }
  return false;
};
