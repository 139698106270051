import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { combineReducers } from "redux";
import auctionsReducer from "./auctions/reducer";
import auctionModalReducer from "./auctionModal/reducer";
import authReducer from "./auth/reducer";
import storesReducer from "./stores/reducer";
import userReducer from "./user/reducer";
import filtersReducer from "./filters/reducer";
import individualAuctionReducer from "./individualAuction/reducer";
import bidActivityReducer from "./bidActivity/reducer";
import paymentsReducer from "./payments/reducer";
import passwordResetReducer from "./passwordReset/reducer";
import eventsReducer from "./events/reducer";
import biddingReducer from "./bidding/reducer";
import liveAuctionsReducer from "./liveAuctions/reducer";

const rootReducer = combineReducers({
  stores: storesReducer,
  auth: authReducer,
  auctions: auctionsReducer,
  auctionModal: auctionModalReducer,
  user: userReducer,
  filters: filtersReducer,
  individualAuction: individualAuctionReducer,
  bidActivity: bidActivityReducer,
  payments: paymentsReducer,
  passwordReset: passwordResetReducer,
  events: eventsReducer,
  bidding: biddingReducer,
  liveAuctions: liveAuctionsReducer,
});

export const saveToLocalStorage = (name: string, state: any) => {
  try {
    if (typeof localStorage === "undefined") {
      return;
    }
    const storage = localStorage || null;
    if (storage) {
      storage.setItem(name, JSON.stringify(state));
    }
  } catch (e) {
    console.error(e);
  }
};

export const loadFromLocalStorage = (name: string) => {
  try {
    if (typeof localStorage === "undefined") {
      return undefined;
    }
    const stateStr = localStorage ? localStorage.getItem(name) : null;
    return stateStr ? JSON.parse(stateStr) : undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const persistedStore = loadFromLocalStorage("state");

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedStore,
});

store.subscribe(() => {
  saveToLocalStorage("state", store.getState());
});

export type AppDispatch = typeof store.dispatch;
export type State = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<any>();
export default store;
