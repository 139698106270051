import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { navigate } from "gatsby";
import { Market } from "../../types/Market";
import { User } from "../../types/User";
import {
  AuctionSettings,
  getAuctionSettings,
  getCurrentBasicAccount,
  getInstagramBasicAccounts,
  getInstagramBasicAuthUrl,
  getNotificationSettings,
  getUserInfo,
  getUserInstagramAccounts,
  InstagramBasicAccounts,
  InstagramBasicInterface,
  NotificationSettingObject,
  patchUserInfo,
  updateAuctionSettings,
  updateNotificationSettings,
} from "./actions";

const initialState = {
  loading: false,
  accountsLoading: false,
  errors: null,
  success: false,
  userInfo: null as User | null,
  markets: [] as Market[],
  requestingUserInfo: false,
  notificationSettings: null as NotificationSettingObject,
  updatedNotificationSettings: false,
  updatedAuctionSettings: false,
  instagramBasicAuthUrl: null as InstagramBasicInterface,
  instagramBasicAccounts: null as InstagramBasicAccounts[],
  currentInstagramBasicAccount: null as InstagramBasicAccounts,
  location: null as string | null,
  lastPage: null as string | null,
  auctionSettings: null as AuctionSettings | null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserInfo(state) {
      state.userInfo = null;
      state.loading = false;
      state.errors = null;
      state.success = false;
      state.markets = [];
      state.updatedAuctionSettings = false;
      state.auctionSettings = null;
      state.requestingUserInfo = false;
      state.notificationSettings = null;
      state.updatedNotificationSettings = false;
      state.instagramBasicAuthUrl = null;
      state.instagramBasicAccounts = null;
      state.currentInstagramBasicAccount = null;
      state.location = null;
    },
    resetNotificationFlag(state) {
      state.updatedNotificationSettings = false;
    },
    setLocation(state, { payload }) {
      state.location = payload;
    },
    setLastPage(state, { payload }) {
      state.lastPage = payload;
    },
    clearLastPage(state) {
      state.lastPage = null;
    },
    clearLocation(state) {
      state.location = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.pending, (state) => {
      state.loading = true;
      state.errors = null;
      state.requestingUserInfo = true;
    });
    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        console.log("Error in get user");
        Cookies.remove("access");
        navigate("/signin/");
      } else {
        state.userInfo = payload;
        state.errors = null;
        state.requestingUserInfo = false;
      }
    });
    builder.addCase(getUserInfo.rejected, (state, { error }) => {
      state.loading = false;
      state.errors = true;
      state.requestingUserInfo = false;
      if (error.message === "Request failed with status code 401") {
        console.log("Error in get user 401");
        Cookies.remove("access");
        navigate("/signin");
      }
    });
    builder.addCase(getUserInstagramAccounts.pending, (state) => {
      state.loading = true;
      state.accountsLoading = true;
      state.errors = null;
    });
    builder.addCase(
      getUserInstagramAccounts.fulfilled,
      (state, { payload }) => {
        state.markets = payload;
        state.accountsLoading = false;
        state.loading = false;
        state.success = true;
      }
    );
    builder.addCase(getUserInstagramAccounts.rejected, (state) => {
      state.loading = false;
      state.accountsLoading = false;
      state.errors = true;
    });
    // Patch User
    builder.addCase(patchUserInfo.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(patchUserInfo.fulfilled, (state, { payload }) => {
      state.userInfo = payload;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(patchUserInfo.rejected, (state, { error }) => {
      state.loading = false;
      state.errors = true;
      if (error.message === "Request failed with status code 401") {
        console.log("Error in patch");
        Cookies.remove("access");
        navigate("/signin");
      }
    });
    // Notification Settings
    builder.addCase(getNotificationSettings.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getNotificationSettings.fulfilled, (state, { payload }) => {
      state.notificationSettings = payload;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getNotificationSettings.rejected, (state) => {
      state.loading = false;
      state.errors = true;
    });

    builder.addCase(updateNotificationSettings.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(updateNotificationSettings.fulfilled, (state) => {
      // state.notificationSettings = payload
      state.updatedNotificationSettings = true;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(updateNotificationSettings.rejected, (state) => {
      state.loading = false;
      state.errors = true;
    });

    // Auction Settings
    builder.addCase(getAuctionSettings.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getAuctionSettings.fulfilled, (state, { payload }) => {
      state.auctionSettings = payload.data;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getAuctionSettings.rejected, (state) => {
      state.loading = false;
      state.errors = true;
    });

    builder.addCase(updateAuctionSettings.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(updateAuctionSettings.fulfilled, (state, { payload }) => {
      // state.notificationSettings = payload
      state.auctionSettings = payload.data;
      state.updatedAuctionSettings = true;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(updateAuctionSettings.rejected, (state) => {
      state.loading = false;
      state.errors = true;
    });

    builder.addCase(getInstagramBasicAuthUrl.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(
      getInstagramBasicAuthUrl.fulfilled,
      (state, { payload }) => {
        state.instagramBasicAuthUrl = payload;
        state.loading = false;
        state.success = true;
      }
    );
    builder.addCase(getInstagramBasicAuthUrl.rejected, (state) => {
      state.loading = false;
      state.errors = true;
    });
    builder.addCase(getInstagramBasicAccounts.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(
      getInstagramBasicAccounts.fulfilled,
      (state, { payload }) => {
        state.instagramBasicAccounts = payload.data;
        state.loading = false;
        state.success = true;
      }
    );
    builder.addCase(getInstagramBasicAccounts.rejected, (state) => {
      state.loading = false;
      state.errors = true;
    });
    builder.addCase(getCurrentBasicAccount.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getCurrentBasicAccount.fulfilled, (state, { payload }) => {
      state.currentInstagramBasicAccount = payload;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getCurrentBasicAccount.rejected, (state) => {
      state.loading = false;
      state.errors = true;
    });
  },
});

export const {
  clearUserInfo,
  resetNotificationFlag,
  setLocation,
  clearLocation,
  setLastPage,
  clearLastPage,
} = userSlice.actions;

export default userSlice.reducer;
