import { createAsyncThunk } from "@reduxjs/toolkit";
import { Market } from "../../types/Market";
import { User } from "../../types/User";
import Axios from "../../extensions/Axios";

interface UserData {
  first_name: string;
  last_name: string;
  email: string;
}
export interface NotificationSetting {
  id: number;
  type: string;
  value: boolean;
}

export interface NotificationSettingObject {
  data: NotificationSetting[];
}

export interface AccountSettingsRequestParams {
  store_id: string | number;
}

export interface AuctionSettings {
  currency: string;
  increment: number;
  price: number;
  duration_days: number;
}

export interface AuctionSettingsPatchParams {
  auction_setting: AuctionSettings;
  store_id: string | number;
}

export interface AccountSettingsObject {
  data: AuctionSettings;
}

export interface NotificationSettingsUpdate {
  value: boolean;
}

export interface UpdateNotificationSettings {
  id: number;
  notification_settings: NotificationSettingsUpdate;
}

export interface PatchUserProps {
  user: UserData;
}

export interface InstagramBasicInterface {
  url: string;
}
export interface InstagramBasicAccounts {
  account_type: string;
  id: number;
  expired: boolean;
  username: string;
}
export interface InstagramBasicAccountsData {
  data: InstagramBasicAccounts[];
}

export const getUserInfo = createAsyncThunk<User | any>(
  "user/getUserInfo",
  async () => {
    try {
      const response = await Axios.get("/user");
      return response.data;
    } catch (err: any) {
      return { error: true, data: err.response.data };
    }
  }
);

export const patchUserInfo = createAsyncThunk<User, PatchUserProps>(
  "user/patchUserInfo",
  async (props) => {
    const response = await Axios.patch(`/user`, { ...props });
    return response.data;
  }
);

// Already Connected Instagram Accounts
export const getUserInstagramAccounts = createAsyncThunk<Market[]>(
  "user/getInstagramAccounts",
  async () => {
    const response = await Axios.get("/markets/instagram");
    return response.data.data;
  }
);

// Connect instagram account
// export const linkInstagramAccount = createAsyncThunk<MarketLink, string>(
//   'user/linkInstagramAccount',
//   async (accountId) => {
//     const response = await Axios.post('/instagram/accounts', {
//       id: accountId,
//     });
//     return response.data;
//   }
// )

// // Get Available Instagram Accounts after authentication
// export const getAvailableInstagramAccounts = createAsyncThunk<InstagramAccount[] | any>(
//   'user/getAvailableInstagramAccounts',
//   async () => {
//     try {
//       const response = await Axios.get('/instagram/accounts')
//       return response.data;
//     } catch (err: any) {
//       return { error: true, data: err.response.data }
//     }

//   }
// )

export const getAuctionSettings = createAsyncThunk<
  AccountSettingsObject,
  AccountSettingsRequestParams
>("user/getAuctionSettings", async (params) => {
  const response = await Axios.get(
    `/stores/${params.store_id}/auction_setting`
  );
  return response.data;
});

export const updateAuctionSettings = createAsyncThunk<
  AccountSettingsObject,
  AuctionSettingsPatchParams
>("user/updateAuctionSettings", async (params) => {
  const response = await Axios.patch(
    `/stores/${params.store_id}/auction_setting`,
    {
      auction_setting: params.auction_setting,
    }
  );
  return response.data;
});

export const getNotificationSettings =
  createAsyncThunk<NotificationSettingObject>(
    "user/getNotificationSettings",
    async () => {
      const response = await Axios.get(`/user/notification_settings`);
      return response.data;
    }
  );

export const updateNotificationSettings = createAsyncThunk<
  NotificationSetting,
  UpdateNotificationSettings
>("user/updateNotificationSettings", async (newSettings) => {
  const response = await Axios.patch(
    `/user/notification_settings/${newSettings.id}`,
    {
      notification_setting: newSettings.notification_settings,
    }
  );
  return response.data;
});

export const getInstagramBasicAuthUrl = createAsyncThunk<
  InstagramBasicInterface,
  any
>("user/getInstagramBasicAuthUrl", async (data) => {
  const response = await Axios.get(
    `/link/instagram_basic?format=json&redirect=${data.url}&state=${data.state}`
  );

  return response.data;
});

export const getInstagramBasicAccounts =
  createAsyncThunk<InstagramBasicAccountsData>(
    "user/getInstagramBasicAccounts",
    async () => {
      const response = await Axios.get(`/instagram_basic/accounts?format=json`);
      return response.data;
    }
  );

export const getCurrentBasicAccount = createAsyncThunk<
  InstagramBasicAccounts,
  string
>("user/getCurrentBasicAccount", async (account_id) => {
  const response = await Axios.get(`/instagram_basic/accounts/${account_id}`);
  return response.data;
});
