import { createSlice } from "@reduxjs/toolkit";
import { Bid, deleteBid, fetchBids } from "./actions";

const initialState = {
  bids: [] as Bid[],
  loading: false,
  error: null,
  successfullyDeletedBid: false,
  errorDeletingBid: false,
};
const bidActivitySlice = createSlice({
  name: "bidActivity",
  initialState,
  reducers: {
    resetBidState(state) {
      state.successfullyDeletedBid = false;
      state.errorDeletingBid = false;
    },
    resetBidActivity(state) {
      state.bids = [];
      state.loading = false;
      state.error = null;
      state.successfullyDeletedBid = false;
      state.errorDeletingBid = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBids.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBids.fulfilled, (state, { payload }) => {
      state.bids = payload;
      state.loading = false;
    });
    builder.addCase(fetchBids.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
    });
    builder.addCase(deleteBid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBid.fulfilled, (state) => {
      state.successfullyDeletedBid = true;
      state.loading = false;
    });
    builder.addCase(deleteBid.rejected, (state) => {
      state.loading = false;
      state.errorDeletingBid = true;
    });
  },
});

export const { resetBidActivity, resetBidState } = bidActivitySlice.actions;

// Reducer
export default bidActivitySlice.reducer;
