import { createSlice } from "@reduxjs/toolkit";
import { sendToast } from "../../utils/helpers";
import { submitBid } from "./actions";

const initialState = {
  loading: false,
  successfullySubmittedBid: false,
  error: null,
};
const biddingSlice = createSlice({
  name: "bidding",
  initialState,
  reducers: {
    clearState(state) {
      state.loading = false;
      state.successfullySubmittedBid = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitBid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(submitBid.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload && payload.error && payload.message) {
        sendToast(payload.message, { type: "error" });
      } else {
        state.successfullySubmittedBid = true;
      }
    });
    builder.addCase(submitBid.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as Error;
    });
  },
});

export const { clearState } = biddingSlice.actions;

// Reducer
export default biddingSlice.reducer;
