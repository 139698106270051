import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Auction } from "../../types/Auction";
import { Post } from "../../types/Post";
import Axios from "../../extensions/Axios";
import { User } from "../../types/User";

/// /
// Async Thunk Actions
/// /

export interface AuctionPartial {
  userInfo: User;
  auction: Auction;
}

export interface UploadProperties {
  uploadUrl: string;
  file: File;
}

export interface NewUploadMeta {
  filename: string;
  mime_type: string;
  path: string;
}
export interface NewUploadData {
  meta: NewUploadMeta;
  url: string;
  imgix_url: string;
}

export interface NewUpload {
  data: NewUploadData;
}

export const selectPost = createAsyncThunk<Post | Error, Post>(
  "auctionModal/selectPost",
  (post) => {
    // TODO: add proper requests here
    try {
      return post;
    } catch {
      return new Error("There was an error fetching posts");
    }
  }
);

export const saveAuction = createAsyncThunk<Auction | Error, AuctionPartial>(
  "auctionModal/saveAuction",
  async (auctionPartial) => {
    try {
      const response = await Axios.post(
        `/stores/${auctionPartial.userInfo.data.last_store_id}/auctions`,
        {
          auction: {
            ...auctionPartial.auction,
          },
        }
      );
      return response.data;
    } catch {
      return new Error("There was an error fetching posts");
    }
  }
);

export const getUploadImageUrl = createAsyncThunk<NewUpload | any, string>(
  "auctionModal/getUploadImageUrl",
  async (format) => {
    try {
      const response = await Axios.get(`/upload/new?format=${format}`);
      return response.data;
    } catch (err) {
      return { error: true };
    }
  }
);

export const uploadImage = createAsyncThunk<any, UploadProperties>(
  "auctionModal/uploadImage",
  async (uploadProperties) => {
    const uploadOptions = {
      headers: {
        "Content-Type": uploadProperties.file.type,
      },
    };
    const response = await axios.put(
      uploadProperties.uploadUrl,
      uploadProperties.file,
      uploadOptions
    );
    if (response.status === 200) {
      return { error: false, data: true };
    }
    return { error: true, message: "There was an error uploading the image" };
  }
);
