import { createSlice } from "@reduxjs/toolkit";
import { getLiveAuctions } from "./actions";

const initialState = {
  auctions: null as any | null,
  loading: false,
  error: null,
};

const liveAuctionsSlice = createSlice({
  name: "liveAuctions",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    resetState(state) {
      state.loading = false;
      state.error = null;
      state.auctions = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLiveAuctions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLiveAuctions.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.hasError) {
        state.error = payload;
      } else {
        state.auctions = payload;
      }
    });
    builder.addCase(getLiveAuctions.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as any;
    });
  },
});

export const { clearError, resetState } = liveAuctionsSlice.actions;

// Reducer
export default liveAuctionsSlice.reducer;
